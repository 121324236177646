<template>
  <div class="logo-menu-logo">
    <img alt="image" src="/logo_bco-1500h.png" class="logo-menu-image" style="margin-top: 30px; margin-bottom: 40px; width: 200px"/>
  </div>
</template>

<style scoped>
.logo-menu-logo {
  width: 300px;
  height: 50px;
  position: relative;
  padding: 0 0 15px 25px;
  background: var(--theme-color-main);
}
.logo-menu-image {
  z-index: 10;
  position: absolute;
  clip: rect(0px, 300px, 200px, 0px);
}
</style>
px