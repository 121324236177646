import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/auth/LoginView.vue";
import DashboardView from "@/views/admin/DashboardView.vue";

const routes = [
  {
    name: "login",
    path: "/",
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "dashboard",
    path: "/dashboard/:id",
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let userId = localStorage.getItem("uid");
  if (to.meta.requiresAuth == true && userId) {
    next();
  } else if(to.meta.requiresAuth == false) {
    next();
  } else {
    next("/")
  }
});
export default router;
