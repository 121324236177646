<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import statusBadge from "@/components/common/status-badge.vue";

const store = useStore();

onMounted(() => {
  store.dispatch("getAllErrors");
});

let errors = computed(() => {
  let finalErrors = store.state.errors.sort(compararDatas);
  return finalErrors;
});

function compararDatas(a, b) {
  const dataA = a.data;
  const dataB = b.data;
  return dataB - dataA;
}
</script>

<template>
  <div class="contedo-dashboard-conteudo">
    <div class="contedo-dashboard-header">
      <div class="contedo-dashboard-display">
        <span class="titulo">Erros</span>
        <span class="subtitulo">Quinta feira, 03 de agosto de 2023</span>
      </div>
    </div>
    <div class="contedo-dashboard-dashboard">
      <div class="contedo-dashboard-resultados">
        <table>
          <thead>
            <tr>
              <th>Error</th>
              <th>Type</th>
              <th>Function</th>
              <th>Status</th>
              <th>Repository</th>
              <th>Server</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in errors" :key="item.id">
              <td>{{ item.error }}</td>
              <td>{{ item.error_type }}</td>
              <td>{{ item.function_error }}</td>
              <td><statusBadge :status="item.status"></statusBadge></td>
              <td>{{ item.repository_url }}</td>
              <td>{{ item.server_url }}</td>
              <td>{{ moment(item.data).format("MMMM Do YYYY, h:mm:ss a") }}</td>
            </tr>
            <!-- Adicione mais linhas conforme necessário -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contedo-dashboard-conteudo {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.contedo-dashboard-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.contedo-dashboard-display {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-dashboard-dashboard {
  gap: 20px;
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: flex-start;
}
.contedo-dashboard-resultados {
  flex: 0 0 auto;
  width: 100%;
  min-height: 400px;
  height: fit-content;
  display: flex;
  border-width: 20px;
  border-color: white;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: flex-start;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
  overflow: scroll;
}
.contedo-dashboard-resultados table {
  width: max-content;
}
@media (max-width: 1220px) {
  .contedo-dashboard-display {
    width: 40%;
  }
}
@media (max-width: 957px) {
  .contedo-dashboard-conteudo {
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
  }
  .contedo-dashboard-header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .contedo-dashboard-display {
    width: 100%;
  }
  .contedo-dashboard-dashboard {
    display: flex;
    flex-direction: column;
  }
  .contedo-dashboard-resultados {
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
</style>
