<script setup>
import { ref } from "vue";
import LogoMenu from "./logo-menu";
import ContedoMenu from "./contedo-menu";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";

const logout = () => {
  signOut(auth).then(() => {
    localStorage.clear();
    console.log("DESLOGOU");
  });
  router.push("/login");
};

const notMinifyed = ref(true);

const toggleMenu = () => {
  const menu = document.querySelector("#menu");
  const content = document.querySelector('#content')
  if (menu.style.width === "80px") {
    menu.style.width = "300px";
    content.style.width = 100% - '300px';
    content.style.marginLeft = '300px';
    notMinifyed.value = true;
  } else {
    menu.style.width = "80px";
    content.style.width = 100% - '80px';
    content.style.marginLeft = '80px';
    notMinifyed.value = false;
  }
};
</script>

<template>
  <div id="menu" v-bind:class="rootClassName">
    <logo-menu></logo-menu>
    <div class="menu-lateral-barra">
      <button  @click="toggleMenu()">
        <ion-icon name="menu-outline"></ion-icon>
      </button>
      <div class="menu-lateral-sections"><contedo-menu></contedo-menu></div>
      <div class="menu-lateral-contentbotton">
        <div class="profile-icon" @click="logout()">
          <ion-icon name="log-in-outline"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#menu {
  width: 300px;
  height: 100%;
  display: flex;
  position: absolute;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--theme-color-main);
  overflow-x: hidden;
  box-sizing: border-box;
  transition: all ease .3s;
}
.menu-lateral-barra {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 70px 20px 35px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.menu-lateral-sections {
  margin-top: 5px;
}
.menu-lateral-contentbotton {
  gap: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 400px;
  flex-direction: column;
  justify-content: space-between;
}
.menu-lateral-acount {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.menu-lateral-root-class-name {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
.menu-lateral-root-class-name1 {
  left: 0px;
}
.menu-lateral-root-class-name2 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
.menu-lateral-root-class-name3 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
.menu-lateral-root-class-name4 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
.menu-lateral-root-class-name5 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
.menu-lateral-root-class-name6 {
  left: 0px;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--theme-color-main);
}
@media (max-width: 767px) {
  #menu {
    width: 80px;
    display: none;
  }
  .menu-lateral-barra {
    padding: 70px 5px 35px;
  }
}
.profile-icon {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.profile-icon :hover {
  cursor: pointer;
}
ion-icon {
  color: white;
  font-size: 35px;
}
.logo-menu-image {
  width: 100%;
  height: 100%;
  padding: 0px 25px;
}
</style>
