import { createStore } from "vuex";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/index";

const store = createStore({
  state: {
    url: "login",
    dialog: {
      message: '',
      type: '',
      isVisible: false,
      icon: ''
    },
    errors: []
  },
  mutations: {
    SET_URL(state, payload) {
      state.url = payload;
    },
    SET_DIALOG(state, payload) {
      state.dialog = payload
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    }
  },
  actions: {
    setDialog({commit}, payload) {
      commit('SET_DIALOG', { message: payload.message, type: payload.type, isVisible: true, icon: payload.icon})
      setTimeout(() => {
        commit('SET_DIALOG', { message: '', type: '', isVisible: false, icon: ''})
      }, "3000");
    },
    getAllErrors({commit, dispatch}) {
      try {
        const q = query(collection(db, 'errors_log'));
        onSnapshot(q, (querySnapshot) => {
          const finalData = [];
          querySnapshot.forEach((doc) => {
            finalData.push({ id: doc.id, ...doc.data() });
          });
          commit('SET_ERRORS', finalData)
        });
      } catch(e) {
        dispatch('setDialog', { message: 'ERROR ON GET FIRESTORE DATA', type: 'danger'})
      }
    }
  }
});

export default store
