<script setup>
import router from '@/router'
import { defineProps } from 'vue';

const props = defineProps({
  image_src: {
    type: String,
    default: "/dashboard_icon.svg",
  },
  rootClassName: String,
  image_alt: {
    type: String,
    default: "image",
  },
  text: {
    type: String,
    default: "Dashboard",
  },
});

const goTo = (text) => {
  var route = "";
  switch (text) {
    case "Dashboard":
      route = "dash";
      break;
    case "Contatos":
      route = "contatos";
      break;
    case "Integrações":
      route = "integrações";
      break;
    case "Helpdesk":
      route = "helpdesk";
      break;
    case "Configurações":
      route = "configurações";
      break;
    default:
      route = "/";
      break;
  }
  router.push('/dashboard/' + route)
};
</script>

<template>
  <div
    id="teste123"
    class="botao-menu-container hover-menu-button"
    v-bind:class="rootClassName"
  >
    <button type="button" class="botao-menu-botao-menu" @click="goTo(text)">
      <img alt="image_alt" :src="props.image_src" class="botao-menu-image" />
      <span class="botao-menu-text">{{ props.text }}</span>
    </button>
  </div>
</template>

<style scoped>
.botao-menu-container {
  width: 100%;
  border: none;
  display: flex;
  padding: 10px 10px 10px 0;
  position: relative;
}
#teste123 :hover {
  cursor: pointer;
}
.botao-menu-botao-menu {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0);
}
.botao-menu-botao-menu :hover {
  cursor: pointer;
}
.botao-menu-image {
  width: 35px;
  object-fit: cover;
}
.botao-menu-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  align-items: center;
  font-family: Fivo Sans Medium;
  margin-left: 17px;
}

@media (max-width: 767px) {
  .botao-menu-text {
    display: none;
  }
}
</style>
