<script setup>
import InputText from "@/components/common/InputText.vue";
import { ref } from "vue";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";
import { useStore } from "vuex";
import InputTextPassword from "../common/InputTextPassword.vue";

const store = useStore();

const email = ref("");
const password = ref("");

const cadastrar = () => {
  if (password.value != "" && email.value != "") {
    try {
      createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((firebaseUser) => {
          localStorage.setItem("uid", firebaseUser.user.uid);
          localStorage.setItem("accessToken", firebaseUser.user.accessToken);
          store.dispatch("setDialog", {
            message: "Usuário criado com sucesso",
            type: "success",
            icon: "person-add",
          });
        })
        .catch((error) => {
          const e = error.message;
          const text =
            e == "Firebase: Error (auth/invalid-email)."
              ? "Erro: E-mail inválido."
              : e ==
                "Firebase: Password should be at least 6 characters (auth/weak-password)."
              ? "Erro: a senha deve ter ao menos 6 caracteres"
              : e == "Firebase: Error (auth/email-already-in-use)."
              ? "Erro: e-mail já em uso."
              : "Erro ao criar um novo usuário, tente novamente mais tarde.";
          store.dispatch("setDialog", {
            message: text,
            type: "danger",
            icon: "alert-circle-outline",
          });
          console.log(error.message);
        })
        .finally(() => {
          router.push("/dashboard/dash");
        });
    } catch (e) {
      store.dispatch("setDialog", {
        message: "Erro ao criar novo usuário, tente novamente mais tarde",
        type: "danger",
        icon: "alert-circle-outline",
      });
    }
  } else {
    store.dispatch("setDialog", {
      message: "Erro! Os campos email e senha são obrigatórios",
      type: "danger",
      icon: "alert-circle-outline",
    });
  }
};
</script>

<template>
  <div class="login-container" :class="String">
    <div class="recuperar-senha-logo">
      <img
        alt="image"
        src="/grafismo-logo_amarelo-200h.png"
        class="logo-menu-image"
        style="width: 70px"
      />
      <span>FLASHVOLVE</span>
    </div>
    <div class="login-sectionname">
      <span class="login-text">Cadastre-se</span>
    </div>
    <div class="login-email">
      <input-text v-model:value="email" label="E-mail"></input-text>
    </div>
    <div class="login-senha">
      <input-text-password
        v-model:value="password"
        label="Senha"
      ></input-text-password>
    </div>
    <div class="login-botao">
      <div class="login-botaoaction" @click="cadastrar">
        <span class="login-text5">Cadastre-se</span>
      </div>
    </div>
    <div class="login-cadastrar" @click="store.commit('SET_URL', 'login')">
      <span class="login-text4"> Já tem uma conta? Faça login! </span>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  max-width: 450px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.recuperar-senha-logo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.recuperar-senha-logo span {
  font-size: 20px;
  font-weight: bold;
  padding-left: 15px;
}
.login-sectionname {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text {
  color: #737373;
  font-size: 25px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.login-email {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text1 {
  color: var(--theme-color-main);
  font-size: 16px;
  font-family: Fivo Sans Bold;
}
.login-textinput {
  width: 100%;
  border-color: #737373;
}
.login-senha {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-text2 {
  color: var(--theme-color-main);
  font-family: Fivo Sans Bold;
}
.login-textinput1 {
  width: 100%;
  border-color: #737373;
}
.login-logininfo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.login-senhainfo {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-manterconectado {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-checkbox {
  width: 18px;
  height: 18px;
}
.login-text3 {
  color: var(--theme-color-main);
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-left: 10px;
}
.login-alterarsenha {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.login-text4 {
  color: #737373;
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.login-botao {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
}
.login-botao :hover {
  cursor: pointer;
}
.login-cadastrar :hover {
  cursor: pointer;
}
.login-botaoaction {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--theme-color-main);
}
.login-text5 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}

@media (max-width: 479px) {
  .login-container {
    padding: 20px;
  }
  .login-sectionname {
    margin-top: 20px;
  }
  .login-text {
    font-size: 20px;
  }
  .login-text3 {
    font-size: 12px;
  }
  .login-text4 {
    font-size: 12px;
  }
}
</style>
