<script setup>
import { defineProps } from "vue";

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

function getStatusColor(status) {
  var final = "";
  switch (status) {
    case "Open":
      final = "warning";
      break;
    case "Critical":
      final = "danger";
      break;
    case "Closed":
      final = "success";
      break;
    case "Not critical":
      final = "info";
      break;
    default:
      final = "warning";
      break;
  }
  return final;
}
</script>

<template>
  <div :class="`status-${getStatusColor(props.status)}`">
    <span class="textbutton">{{ props.status }}</span>
  </div>
</template>