import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtE6AjeQxXXelVvVjkeAushX7c9f75MkQ",
  authDomain: "sql-flasvolve.firebaseapp.com",
  projectId: "sql-flasvolve",
  storageBucket: "sql-flasvolve.appspot.com",
  messagingSenderId: "575456384632",
  appId: "1:575456384632:web:0110b96c03d4aef9007f8d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)

export { auth, db }