<script setup>
import InputText from "@/components/common/InputText.vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/firebase/index";
import router from "@/router/index";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import InputTextPassword from "../common/InputTextPassword.vue";

const store = useStore();

const email = ref("");
const password = ref("");

onMounted(() => {
  let user = auth.currentUser;
  if (user != null) {
    localStorage.setItem("uid", user.uid);
    localStorage.setItem("accessToken", user.accessToken);
    router.push("/dashboard/dash");
  } else if (
    localStorage.getItem("uid") &&
    localStorage.getItem("accessToken")
  ) {
    router.push("/dashboard/dash");
  }
});

const login = () => {
  console.log(`EMAIL ==>> ${email.value} \n PASSWORD ==>> ${password.value}`);
  try {
    signInWithEmailAndPassword(auth, email.value, password.value)
      .then((firebaseUser) => {
        localStorage.setItem("uid", firebaseUser.user.uid);
        localStorage.setItem("accessToken", firebaseUser.user.accessToken);
        console.log("LOGOU");
      })
      .finally(() => {
        router.push("/dashboard/dash");
      });
  } catch (e) {
    console.log(e);
  }
};
</script>

<template>
  <div class="login-container" v-bind:class="String">
    <div class="recuperar-senha-logo">
      <img
        alt="image"
        src="/grafismo-logo_amarelo-200h.png"
        class="logo-menu-image"
        style="width: 70px"
      />
      <span>FLASHVOLVE</span>
    </div>
    <div class="login-sectionname">
      <span class="login-text"> Login </span>
    </div>
    <div class="login-email">
      <input-text label="E-mail" v-model:value="email"></input-text>
    </div>
    <div class="login-senha">
      <input-text-password
        v-model:value="password"
        label="Senha"
      ></input-text-password>
    </div>
    <div class="login-logininfo">
      <div class="login-senhainfo">
        <div class="login-manterconectado">
          <input type="checkbox" class="login-checkbox" />
          <span class="login-text3">Manter conectado</span>
        </div>
        <div
          class="login-alterarsenha"
          @click="store.commit('SET_URL', 'reset')"
        >
          <span class="login-text4">Alterar senha</span>
        </div>
      </div>
    </div>
    <div class="login-botao">
      <div class="login-botaoaction" @click="login()">
        <span class="login-text5">Entrar</span>
      </div>
    </div>
    <div class="login-cadastrar" @click="store.commit('SET_URL', 'cadastro')">
      <span class="login-text4"> Não tem uma conta? Cadastre-se! </span>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  max-width: 450px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.recuperar-senha-logo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.recuperar-senha-logo span {
  font-size: 20px;
  font-weight: bold;
  padding-left: 15px;
}
.login-sectionname {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text {
  color: #737373;
  font-size: 25px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.login-email {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  flex-direction: column;
}
.login-text1 {
  color: var(--theme-color-main);
  font-size: 16px;
  font-family: Fivo Sans Bold;
}
.login-textinput {
  width: 100%;
  border-color: #737373;
}
.login-senha {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-text2 {
  color: var(--theme-color-main);
  font-family: Fivo Sans Bold;
}
.login-textinput1 {
  width: 100%;
  border-color: #737373;
}
.login-logininfo {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.login-senhainfo {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-manterconectado {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
  justify-content: space-between;
}
.login-checkbox {
  width: 18px;
  height: 18px;
}
.login-text3 {
  color: var(--theme-color-main);
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-left: 10px;
}
.login-alterarsenha {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.login-alterarsenha :hover {
  cursor: pointer;
}
.login-cadastrar {
  text-align: center;
}
.login-cadastrar :hover {
  cursor: pointer;
}
.login-text4 {
  color: #737373;
  font-size: 16px;
  font-family: Fivo Sans Bold;
  margin-right: 10px;
}
.login-botao {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
}
.login-botao :hover {
  cursor: pointer;
}
.login-botaoaction {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--theme-color-main);
}
.login-text5 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}

@media (max-width: 479px) {
  .login-container {
    padding: 20px;
  }
  .login-sectionname {
    margin-top: 20px;
  }
  .login-text {
    font-size: 20px;
  }
  .login-text3 {
    font-size: 12px;
  }
  .login-text4 {
    font-size: 12px;
  }
}
</style>
